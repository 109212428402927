@layer modules, ui, base;
@layer base {
  .UserAvatar_userAvatar--default___OA31 {
  background-color: var(--sk-color-grey-100);
  color: var(--sk-color-black);
}

.UserAvatar_userAvatar--image__qvVLz {
  -o-object-fit: cover;
     object-fit: cover;
}

}
